@import "https://fonts.googleapis.com/css?family=Quicksand:300, 700";
html, body {
  box-sizing: border-box;
  height: 100%;
  margin: 0;
  padding: 0;
}

*, :before, :after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

a, a:link, a:visited, a:hover, a:active {
  color: inherit;
  text-decoration: none;
}

ul {
  list-style: none;
}

@font-face {
  font-family: Appgrade Bold;
  src: url("MADE-Evolve-Sans-Bold-EVO.efc09889.otf");
}

@font-face {
  font-family: Appgrade Light;
  src: url("MADE-Evolve-Sans-Light-EVO.510a5934.otf");
}

@font-face {
  font-family: Appgrade Medium;
  src: url("MADE-Evolve-Sans-Medium-EVO.e12d2034.otf");
}

@font-face {
  font-family: Appgrade Regular;
  src: url("MADE-Evolve-Sans-Regular-EVO.7572f268.otf");
}

@font-face {
  font-family: Appgrade Thin;
  src: url("MADE-Evolve-Sans-Thin-EVO.e520c455.otf");
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  font-family: Quicksand, sans-serif;
  font-size: 1.1rem;
  font-weight: 300;
}

::selection {
  background: #cfdac1;
}

.container {
  position: relative;
}

header {
  max-width: 1210px;
  margin: 0 auto;
}

.home {
  color: #fff;
  min-height: 155vh;
  position: relative;
}

@media (width >= 750px) {
  .home {
    min-height: 110vh;
  }
}

@media screen and (width >= 375px) and (width <= 812px) and (orientation: landscape) {
  .home {
    min-height: 170vh;
  }
}

.home:before {
  content: "";
  z-index: -1;
  opacity: 1;
  background-color: #0000;
  background-image: linear-gradient(#00000080, #00000080), url("bg-image2.36cd39a4.jpeg");
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.home header {
  margin-bottom: 3rem;
}

@media (width >= 500px) {
  .home header {
    height: 153px;
    margin-bottom: 0;
  }
}

@media screen and (width >= 375px) and (width <= 812px) and (orientation: landscape) {
  .home header {
    height: 100px;
  }
}

.home header nav {
  grid-template-columns: 1fr;
  padding: 1em 2rem 0;
  display: grid;
}

@media (width >= 500px) {
  .home header nav {
    grid-gap: 2rem;
    text-align: initial;
    grid-template-columns: auto;
    padding: 4rem 2rem 0;
  }
}

@media (width >= 900px) {
  .home header nav {
    grid-template-columns: 1fr 2fr;
  }
}

@media screen and (width >= 375px) and (width <= 812px) and (orientation: landscape) {
  .home header nav {
    padding: 2rem 2rem 0;
  }
}

.home header nav .nav-link-list {
  grid-template-columns: auto auto;
  place-items: center;
  display: grid;
}

@media (width >= 500px) {
  .home header nav .nav-link-list {
    grid-template-columns: auto auto auto auto;
  }
}

.home header nav .nav-link-list li {
  padding: 10px 0;
  font-size: 1.5rem;
  position: relative;
}

@media (width >= 500px) {
  .home header nav .nav-link-list li {
    padding: 0;
  }
}

.home header nav .nav-link-list li a {
  -o-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.home header nav .nav-link-list li a:before {
  content: "";
  visibility: hidden;
  background-color: #fff;
  width: 100%;
  height: 2px;
  transition: all .3s ease-in-out;
  position: absolute;
  bottom: -5px;
  left: 0;
  transform: scaleX(0);
}

.home header nav .nav-link-list li a:hover {
  color: #fff;
}

.home header nav .nav-link-list li a:hover:before {
  visibility: visible;
  transform: scaleX(1);
}

.home header nav .header-image-wrapper {
  display: none;
}

@media (width >= 900px) {
  .home header nav .header-image-wrapper {
    display: inline;
  }
}

.home .home-company-name {
  padding-top: 10vh;
  font-family: Appgrade Thin, sans-serif;
  position: relative;
}

@media (width >= 900px) {
  .home .home-company-name {
    padding-top: 0;
  }
}

@media screen and (width >= 375px) and (width <= 812px) and (orientation: landscape) {
  .home .home-company-name {
    padding-top: 0;
  }
}

.home .home-company-name span {
  display: none;
}

@media (width >= 900px) {
  .home .home-company-name span {
    font-size: 19vw;
    display: block;
  }
}

@media (width >= 1800px) {
  .home .home-company-name span {
    font-size: 17vw;
  }
}

.home .home-company-name .home-company-logo {
  fill: #fff;
  max-width: 90%;
  height: 40vh;
  margin: 0 auto;
  display: block;
}

@media screen and (width >= 375px) and (width <= 812px) and (orientation: landscape) {
  .home .home-company-name .home-company-logo {
    height: 60vh;
  }
}

@media (width >= 900px) {
  .home .home-company-name .home-company-logo {
    display: none;
  }
}

.home .home-intro-text {
  text-align: center;
  z-index: 3;
  width: 100%;
  padding: 1rem;
  font-size: 1.9rem;
  position: absolute;
  top: 100vh;
}

@media (width >= 750px) {
  .home .home-intro-text {
    top: 70vh;
  }
}

@media screen and (width >= 375px) and (width <= 812px) and (orientation: landscape) {
  .home .home-intro-text {
    top: 100vh;
  }
}

@media (width >= 900px) {
  .home .home-intro-text {
    text-align: right;
    width: 75%;
    padding: 0;
    font-size: 2.5rem;
    position: absolute;
    bottom: 25vh;
    right: 5vw;
  }
}

.home .home-end-of-section {
  z-index: 1;
  width: 100%;
  position: absolute;
  bottom: -7px;
}

.home .home-end-of-section .home-svg-path {
  fill: #fff;
  stroke: #0000;
}

.team {
  color: #000;
  min-height: 90vh;
  padding-top: 10vh;
  position: relative;
}

.team .team-content {
  width: 100%;
  padding: 2vw;
}

.team .team-content-title {
  text-align: center;
  font-size: 10vw;
}

@media (width >= 900px) {
  .team .team-content-title {
    text-align: left;
  }
}

.team .team-content-paragraph {
  text-align: center;
  padding: 60px 0;
  font-size: 1.6rem;
}

@media (width >= 900px) {
  .team .team-content-paragraph {
    text-align: left;
  }
}

.team .team-content-images {
  padding-bottom: 4%;
}

.team .team-content-images-title {
  text-align: center;
  padding: 0 0 10vw 2vw;
  font-size: 10vw;
}

@media (width >= 1800px) {
  .team .team-content-images {
    grid-template-columns: 1fr 1fr;
    display: grid;
  }
}

.team .team-content-images .moji {
  width: 100%;
  transition: all .7s ease-in-out;
}

.team .team-content-images .moji:hover {
  transform: scale(1.1);
}

.team .team-content-images .right {
  text-align: center;
  grid-template-columns: 1fr;
  place-items: center;
  display: grid;
}

@media (width >= 600px) {
  .team .team-content-images .right {
    grid-gap: 2rem;
    grid-template-columns: 1fr 1fr;
    justify-items: right;
  }
}

.team .team-content-images .right .about-us {
  padding: 0 5% 15%;
  font-size: 1.6rem;
}

@media (width >= 600px) {
  .team .team-content-images .right .about-us {
    padding: 10% 10% 10% 0;
  }
}

.team .team-content-images .right .avatar {
  max-width: 400px;
  margin: 0 auto;
}

.team .team-content-images .left {
  text-align: center;
  grid-template-areas: "avatar"
                       "about-us";
  place-items: center;
  width: 100%;
  display: grid;
}

@media (width >= 600px) {
  .team .team-content-images .left {
    grid-gap: 2rem;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "about-us avatar";
    justify-items: left;
  }
}

.team .team-content-images .left .about-us {
  grid-area: about-us;
  padding: 0 5% 15%;
  font-size: 1.6rem;
}

@media (width >= 600px) {
  .team .team-content-images .left .about-us {
    padding: 10% 0 10% 10%;
  }
}

.team .team-content-images .left .avatar {
  grid-area: avatar;
  max-width: 400px;
}

.team .team-content-images .unknown-circle {
  color: #999;
  -webkit-user-select: none;
  user-select: none;
  border: 6px dashed #999;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 175px;
  height: 175px;
  margin-bottom: 1rem;
  font-size: 6rem;
  font-weight: bold;
  display: flex;
}

@media (width >= 750px) {
  .team .team-content-images .unknown-circle {
    border: 8px dashed #999;
    width: 250px;
    height: 250px;
    margin: 0;
    font-size: 8rem;
  }
}

@media (width >= 600px) {
  .team .team-content-title {
    font-size: 4rem;
  }

  .team .team-content-images-title {
    padding: 0 0 5vw 2vw;
    font-size: 4rem;
  }

  .team .team-content-paragraph {
    padding: 60px 0;
    font-size: 1.7rem;
  }
}

@media (width >= 900px) {
  .team .team-content {
    width: 70%;
  }
}

.techs {
  background-image: url("office.26f91c83.jpeg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  padding-top: 25vh;
  position: relative;
  overflow: hidden;
}

.techs-begin-of-section {
  position: absolute;
  top: -6px;
  left: -2px;
  right: -2px;
  transform: rotate(180deg);
}

.techs-begin-of-section .techs-begin-svg-path {
  fill: #fff;
}

.techs .techs-content {
  text-align: center;
  justify-content: center;
  padding-top: 5vh;
  padding-bottom: 20vh;
  display: grid;
}

@media (width >= 600px) {
  .techs .techs-content {
    padding-top: 10vh;
  }
}

@media screen and (width >= 375px) and (width <= 812px) and (orientation: landscape) {
  .techs .techs-content {
    padding-bottom: 40vh;
  }
}

.techs-images {
  grid-gap: 2rem;
  grid-template-columns: auto;
  place-items: center;
  display: grid;
}

@media (width >= 500px) {
  .techs-images {
    grid-template-columns: auto auto;
  }
}

@media (width >= 1200px) {
  .techs-images {
    grid-template-columns: auto auto auto auto;
  }
}

.techs .techs-logo {
  width: 120px;
  height: 120px;
  margin: 1rem;
}

.techs .techs-text {
  margin: 1rem;
  font-size: 2.7rem;
}

.techs .techs-title {
  color: #fff;
  font-size: 3.5rem;
}

.techs .techs-end-of-section {
  width: 100%;
  position: absolute;
  bottom: -6px;
}

.techs .techs-end-of-section .techs-svg-path {
  fill: #cfdac1;
}

.portfolio {
  background: #cfdac1;
  min-height: 60vh;
  padding: 2rem;
  position: relative;
}

.portfolio-content {
  text-align: center;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 10rem;
}

@media (width >= 600px) {
  .portfolio-content {
    width: 70%;
    padding-bottom: 15rem;
  }
}

.portfolio-content-title {
  padding-top: 40px;
  font-size: 3.2rem;
}

.portfolio-content-customer {
  grid-gap: 2rem;
  grid-template-columns: 1fr;
  place-items: center;
  margin: 3rem 0;
  display: grid;
}

@media (width >= 600px) {
  .portfolio-content-customer {
    grid-template-columns: 1fr 1fr;
  }
}

.portfolio-content-customer-logo {
  width: 100%;
  max-width: 400px;
  padding-top: 25px;
}

.portfolio-content-customer-text {
  font-size: 2rem;
}

.portfolio .portfolio-end-of-section {
  width: 100%;
  position: absolute;
  bottom: -7px;
  left: 0;
}

.portfolio .portfolio-end-of-section .portfolio-svg-path {
  fill: #fff;
}

.contact {
  min-height: 50vh;
  padding-bottom: 30vh;
  position: relative;
}

@media (width >= 600px) {
  .contact {
    min-height: 80vh;
  }
}

@media screen and (width >= 375px) and (width <= 812px) and (orientation: landscape) {
  .contact {
    padding-bottom: 50vh;
  }
}

.contact-bg {
  z-index: -1;
  text-align: center;
  position: absolute;
  inset: 0;
}

.contact-bg-svg {
  width: 100%;
  height: 100%;
}

.contact-bg-svg-path {
  fill: #eaeaea;
}

.contact-content {
  text-align: center;
  padding: 2rem;
  position: relative;
  top: 10vh;
}

@media (width >= 600px) {
  .contact-content {
    top: 30vh;
  }
}

.contact-content-title {
  margin-bottom: 60px;
  font-size: 2.2rem;
}

@media (width >= 500px) {
  .contact-content-title {
    font-size: 3rem;
  }
}

.contact-content-mail-wrapper {
  text-align: center;
  margin: 20px 0;
  font-size: 1.8rem;
}

.contact-content-mail {
  color: #000;
  -o-transition: all .4s ease-in-out;
  font-size: 2rem;
  text-decoration: none;
  transition: all .4s ease-in-out;
  position: relative;
}

@media (width >= 500px) {
  .contact-content-mail {
    font-size: 2.6rem;
  }
}

.contact-content-mail:before {
  content: "";
  visibility: hidden;
  background-color: #85a161;
  width: 100%;
  height: 2px;
  transition: all .3s ease-in-out;
  position: absolute;
  bottom: -5px;
  left: 0;
  transform: scaleX(0);
}

.contact-content-mail:hover {
  color: #85a161;
}

.contact-content-mail:hover:before {
  visibility: visible;
  transform: scaleX(1);
}

.text-highlight {
  z-index: 1;
  font-style: normal;
  display: inline-block;
  position: relative;
}

.text-highlight:before {
  content: " ";
  z-index: -1;
  background-image: url("highlight.ac4957ba.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%, 100% 100%;
  width: 0%;
  transition: all 1.25s cubic-bezier(.19, 1, .22, 1) .7s;
  position: absolute;
  inset: 5% -5% -5% -3%;
  transform: rotate(-1deg);
}

.text-highlight.highlight:before {
  width: 100%;
}

.invisible {
  opacity: 0;
  transition: transform 1s, opacity 1s, -webkit-transform 1s, -moz-transform 1s, -o-transform 1s;
  transform: translate(0, -10px);
}

.visible {
  opacity: 1;
  transition: transform 2.5s, opacity 2.5s, -webkit-transform 2.5s, -moz-transform 2.5s, -o-transform 2.5s;
  transform: translate(0);
}

footer {
  color: #fff;
  text-align: center;
  background-color: #1b1b1b;
  width: 100%;
  padding: 25px 0;
  font-size: 1.3rem;
}

/*# sourceMappingURL=index.84db23ec.css.map */
