@import url('https://fonts.googleapis.com/css?family=Quicksand:300, 700');

/* Resets */
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // scroll-behavior: smooth;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

a,
a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
  color: inherit;
}

ul {
  list-style: none;
}

@font-face {
  font-family: 'Appgrade Bold';
  src: url('fonts/MADE-Evolve-Sans-Bold-EVO.otf');
}

@font-face {
  font-family: 'Appgrade Light';
  src: url('fonts/MADE-Evolve-Sans-Light-EVO.otf');
}

@font-face {
  font-family: 'Appgrade Medium';
  src: url('fonts/MADE-Evolve-Sans-Medium-EVO.otf');
}

@font-face {
  font-family: 'Appgrade Regular';
  src: url('fonts/MADE-Evolve-Sans-Regular-EVO.otf');
}

@font-face {
  font-family: 'Appgrade Thin';
  src: url('fonts/MADE-Evolve-Sans-Thin-EVO.otf');
}

@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}

@mixin for-home-only-small {
  @media (min-width: 500px) {
    @content;
  }
}

@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}

@mixin for-home-only-big {
  @media (min-width: 750px) {
    @content;
  }
}

@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}

@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}

@mixin landscape {
  @media screen and (min-width: 375px) and (max-width: 812px) and (orientation: landscape) {
    @content;
  }
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.1rem;
  text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-family: 'Quicksand', sans-serif;
  font-weight: 300;
}

/* --------------------------- */
::selection {
  background: rgb(207, 218, 193);
}

.container {
  position: relative;
  // overflow: hidden;
}

header {
  max-width: 1210px;
  margin: 0 auto;
}

.home {
  min-height: 155vh;
  position: relative;
  color: white;

  @include for-home-only-big {
    min-height: 110vh;
  }

  @include landscape {
    min-height: 170vh;
  }

  &::before {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url('../img/bg-image2.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    content: '';
    width: 100%;
    height: 100%;
    z-index: -1;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 1;
    background-position: 50% 0;
  }

  header {
    margin-bottom: 3rem;

    @include for-home-only-small {
      height: 153px;
      margin-bottom: 0;
    }

    @include landscape {
      height: 100px;
    }

    nav {
      display: grid;
      grid-template-columns: 1fr;
      padding: 1em 2rem 0 2rem;

      @include for-home-only-small {
        grid-gap: 2rem;
        text-align: initial;
        grid-template-columns: auto;
        padding: 4rem 2rem 0 2rem;
      }

      @include for-tablet-landscape-up {
        grid-template-columns: 1fr 2fr;
      }

      @include landscape {
        padding: 2rem 2rem 0 2rem;
      }

      .nav-link-list {
        display: grid;
        grid-template-columns: auto auto;
        align-items: center;
        justify-items: center;

        @include for-home-only-small {
          grid-template-columns: auto auto auto auto;
        }

        li {
          padding: 10px 0;
          font-size: 1.5rem;
          position: relative;

          @include for-home-only-small {
            padding: 0;
          }

          a {
            transition: all 0.4s ease-in-out;
            -webkit-transition: all 0.4s ease-in-out;
            -moz-transition: all 0.4s ease-in-out;
            -o-transition: all 0.4s ease-in-out;

            &:before {
              content: '';
              position: absolute;
              width: 100%;
              height: 2px;
              bottom: -5px;
              left: 0;
              background-color: white;
              visibility: hidden;
              -webkit-transform: scaleX(0);
              transform: scaleX(0);
              -webkit-transition: all 0.3s ease-in-out 0s;
              transition: all 0.3s ease-in-out 0s;
            }

            &:hover {
              color: white;

              &:before {
                visibility: visible;
                -webkit-transform: scaleX(1);
                transform: scaleX(1);
              }
            }
          }
        }
      }

      .header-image-wrapper {
        display: none;

        @include for-tablet-landscape-up {
          display: inline;
        }
      }
    }
  }

  .home-company-name {
    position: relative;
    font-family: 'Appgrade Thin', sans-serif;
    padding-top: 10vh;

    @include for-tablet-landscape-up {
      padding-top: 0;
    }

    @include landscape {
      padding-top: 0;
    }

    span {
      display: none;

      @include for-tablet-landscape-up {
        font-size: 19vw;
        display: block;
      }

      @include for-big-desktop-up {
        font-size: 17vw;
      }
    }

    .home-company-logo {
      max-width: 90%;
      margin: 0 auto;
      display: block;
      fill: white;
      height: 40vh;

      @include landscape {
        height: 60vh;
      }

      @include for-tablet-landscape-up {
        display: none;
      }
    }
  }

  .home-intro-text {
    text-align: center;
    width: 100%;
    font-size: 1.9rem;
    z-index: 3;
    position: absolute;
    top: 100vh;
    padding: 1rem;

    @include for-home-only-big {
      top: 70vh;
    }

    @include landscape {
      top: 100vh;
    }

    @include for-tablet-landscape-up {
      text-align: right;
      font-size: 2.5rem;
      position: absolute;
      bottom: 25vh;
      right: 5vw;
      width: 75%;
      padding: 0;
    }
  }

  .home-end-of-section {
    width: 100%;
    z-index: 1;
    position: absolute;
    bottom: -7px;

    .home-svg-path {
      fill: white;
      stroke: transparent;
    }
  }
}

.team {
  min-height: 90vh;
  color: black;
  position: relative;

  padding-top: 10vh;

  .team-content {
    padding: 2vw;

    width: 100%;

    &-title {
      font-size: 10vw;
      text-align: center;

      @include for-tablet-landscape-up {
        text-align: left;
      }
    }

    &-paragraph {
      font-size: 1.6rem;
      padding: 60px 0;
      text-align: center;

      @include for-tablet-landscape-up {
        text-align: left;
      }
    }

    &-images {
      padding-bottom: 4%;

      &-title {
        padding: 0 0 10vw 2vw;
        font-size: 10vw;
        text-align: center;
      }

      @include for-big-desktop-up {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }

      .moji {
        width: 100%;
        transition: all 0.7s ease-in-out;

        &:hover {
          transform: scale(1.1);
        }
      }

      .right {
        display: grid;
        grid-template-columns: 1fr;
        text-align: center;
        align-items: center;
        justify-items: center;

        @include for-tablet-portrait-up {
          grid-template-columns: 1fr 1fr;
          grid-gap: 2rem;
          justify-items: right;
        }

        .about-us {
          padding: 0 5% 15%;
          font-size: 1.6rem;

          @include for-tablet-portrait-up {
            padding: 10% 10% 10% 0;
          }
        }

        .avatar {
          max-width: 400px;
          margin: 0 auto;
        }
      }

      .left {
        display: grid;
        width: 100%;
        text-align: center;
        align-items: center;
        justify-items: center;

        grid-template-areas:
          'avatar'
          'about-us';

        @include for-tablet-portrait-up {
          grid-template-columns: 1fr 1fr;
          grid-template-areas: 'about-us avatar';
          grid-gap: 2rem;
          justify-items: left;
        }

        .about-us {
          padding: 0 5% 15%;
          font-size: 1.6rem;
          grid-area: about-us;

          @include for-tablet-portrait-up {
            padding: 10% 0 10% 10%;
          }
        }

        .avatar {
          grid-area: avatar;
          max-width: 400px;
        }
      }

      .unknown-circle {
        @include for-home-only-big {
          width: 250px;
          height: 250px;
          border: 8px dashed #999;
          font-size: 8rem;
          margin: 0;
        }

        width: 175px;
        height: 175px;
        border: 6px dashed #999;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 6rem;
        font-weight: bold;
        color: #999;
        user-select: none;
        margin-bottom: 1rem;
      }
    }

    @include for-tablet-portrait-up {
      &-title {
        font-size: 4rem;
      }

      &-images-title {
        font-size: 4rem;
        padding: 0 0 5vw 2vw;
      }

      &-paragraph {
        font-size: 1.7rem;
        padding: 60px 0;
      }
    }

    @include for-tablet-landscape-up {
      width: 70%;
    }
  }
}

.techs {
  background-image: url('../img/office.jpeg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  min-height: 100vh;
  padding-top: 25vh;
  position: relative;
  overflow: hidden;

  &-begin-of-section {
    position: absolute;
    top: -6px;
    transform: rotate(180deg);
    left: -2px;
    right: -2px;

    .techs-begin-svg-path {
      fill: white;
    }
  }

  .techs-content {
    display: grid;
    justify-content: center;
    text-align: center;
    padding-bottom: 20vh;
    padding-top: 5vh;

    @include for-tablet-portrait-up {
      padding-top: 10vh;
    }

    @include landscape {
      padding-bottom: 40vh;
    }
  }

  &-images {
    grid-template-columns: auto;
    display: grid;
    align-items: center;
    justify-items: center;
    grid-gap: 2rem;

    @include for-home-only-small {
      grid-template-columns: auto auto;
    }

    @include for-desktop-up {
      grid-template-columns: auto auto auto auto;
    }
  }

  .techs-logo {
    width: 120px;
    height: 120px;
    margin: 1rem;
  }

  .techs-text {
    font-size: 2.7rem;
    margin: 1rem;
  }

  .techs-title {
    color: white;
    font-size: 3.5rem;
  }

  .techs-end-of-section {
    width: 100%;
    position: absolute;
    bottom: -6px;

    .techs-svg-path {
      fill: rgba(207, 218, 193, 1);
    }
  }
}

.portfolio {
  min-height: 60vh;
  position: relative;
  padding: 2rem;

  background: rgb(207, 218, 193);

  &-content {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding-bottom: 10rem;

    @include for-tablet-portrait-up {
      width: 70%;
      padding-bottom: 15rem;
    }

    &-title {
      font-size: 3.2rem;
      padding-top: 40px;
    }

    &-customer {
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
      justify-items: center;
      grid-gap: 2rem;
      margin: 3rem 0;

      @include for-tablet-portrait-up {
        grid-template-columns: 1fr 1fr;
      }

      &-logo {
        width: 100%;
        max-width: 400px;
        padding-top: 25px;
      }

      &-text {
        font-size: 2rem;
      }
    }
  }

  .portfolio-end-of-section {
    width: 100%;
    position: absolute;
    bottom: -7px;
    left: 0;

    .portfolio-svg-path {
      fill: rgb(255, 255, 255);
    }
  }
}

.contact {
  min-height: 50vh;
  position: relative;
  padding-bottom: 30vh;

  @include for-tablet-portrait-up {
    min-height: 80vh;
  }

  @include landscape {
    padding-bottom: 50vh;
  }

  &-bg {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: -1;
    text-align: center;

    &-svg {
      height: 100%;
      width: 100%;

      &-path {
        fill: #eaeaea;
      }
    }
  }

  &-content {
    text-align: center;
    position: relative;
    top: 10vh;
    padding: 2rem;

    @include for-tablet-portrait-up {
      top: 30vh;
    }

    &-title {
      font-size: 2.2rem;
      margin-bottom: 60px;

      @include for-home-only-small {
        font-size: 3rem;
      }
    }

    &-mail-wrapper {
      margin: 20px 0;
      text-align: center;
      font-size: 1.8rem;
    }

    &-mail {
      position: relative;
      color: #000;
      text-decoration: none;

      font-size: 2rem;
      transition: all 0.4s ease-in-out;
      -webkit-transition: all 0.4s ease-in-out;
      -moz-transition: all 0.4s ease-in-out;
      -o-transition: all 0.4s ease-in-out;
      color: black;

      @include for-home-only-small {
        font-size: 2.6rem;
      }

      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: -5px;
        left: 0;
        background-color: darken(rgba(207, 218, 193, 1), 30%);
        visibility: hidden;
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transition: all 0.3s ease-in-out 0s;
        transition: all 0.3s ease-in-out 0s;
      }

      &:hover {
        color: darken(rgba(207, 218, 193, 1), 30%);

        &:before {
          visibility: visible;
          -webkit-transform: scaleX(1);
          transform: scaleX(1);
        }
      }
    }
  }
}

.text-highlight {
  position: relative;
  display: inline-block;
  font-style: normal;
  z-index: 1;

  &:before {
    content: '\0020';
    position: absolute;
    top: 5%;
    right: -5%;
    bottom: -5%;
    left: -3%;
    width: 0%;
    z-index: -1;
    -ms-transform: rotate(-1deg);
    transform: rotate(-1deg);
    background-image: -webkit-linear-gradient(left, #fff, #fff), url(../img/svg/highlight.svg);
    background-image: url(../img/svg/highlight.svg);
    background-size: 100% 100%, 100% 100%;
    background-repeat: no-repeat;
    -webkit-transition: all 1.25s cubic-bezier(0.19, 1, 0.22, 1) 0.7s;
    transition: all 1.25s cubic-bezier(0.19, 1, 0.22, 1) 0.7s;
  }

  &.highlight {
    &:before {
      width: 100%;
    }
  }
}

.invisible {
  transition: transform 1s ease, opacity 1s ease;
  transition: transform 1s ease, opacity 1s ease, -webkit-transform 1s ease, -moz-transform 1s ease,
    -o-transform 1s ease;
  -webkit-transform: translate(0, -10px);
  -moz-transform: translate(0, -10px);
  -ms-transform: translate(0, -10px);
  -o-transform: translate(0, -10px);
  transform: translate(0, -10px);
  opacity: 0;
}

.visible {
  transition: transform 2.5s ease, opacity 2.5s ease;
  transition: transform 2.5s ease, opacity 2.5s ease, -webkit-transform 2.5s ease,
    -moz-transform 2.5s ease, -o-transform 2.5s ease;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  opacity: 1;
}

footer {
  background-color: rgb(27, 27, 27);
  width: 100%;
  color: white;
  text-align: center;
  padding: 25px 0;
  font-size: 1.3rem;
}
